







































































































// Core
import { Component, Prop, Watch, Mixins, PropSync } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import { documentsNamespace } from '@store/documents'
import { userNamespace } from '@store/user'

// Types
import { UserActionTypes } from '@store/user/Types'
import { TaskActions } from '@store/task/types'

// Interfaces
import { ITask } from '../../interfaces/Interface'
import { IDocument, IDocumentTypes } from '@store/documents/Interface'
import { DocumentsActionTypes } from '@store/documents/Types'
import { Team, TeamState } from '@store/team/interfaces'
import { ElForm } from 'element-ui/types/form'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'
import { Task } from '@/store/task/interfaces'
import { RequestParams } from '@/store/interfaces'

// Modules
const NSTeam = namespace('teamModule')

interface Form {
  id: number
  projectId: number
  result: string
  fileList: any[]
  documentIds: number[]
}
const NSTask = namespace('taskModule')

@Component({
  name: 'DialogTaskExecute',

  components: {
    'v-dialog-choose-file': () => import('@/components/dialogs/DialogChooseFile.vue'),
  },
})
export default class DialogTaskExecuteComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop({ default: () => null })
  private documentation!: IDocument

  @Prop({ default: true })
  private showCategory!: boolean

  @Prop({ default: false })
  private disabledCategory!: boolean

  @Prop({ default: 'Изменить' })
  private buttonSendName!: IDocument

  @Prop({ default: null })
  private task!: ITask

  @Prop({ default: null })
  private category!: number

  @documentsNamespace.State('documentTypes')
  private documentType!: IDocumentTypes

  @NSTeam.State((state: TeamState) => state.team.data)
  private team!: Team[]

  @NSTask.Action(TaskActions.A_CREATE_TASK)
  private createTask!: (form: Form) => Promise<Task>

  @userNamespace.Action(UserActionTypes.A_FINISH_TASK_IN)
  private executeTask!: (payload: any) => Promise<void>

  private isExpectation: boolean = false

  private visibleDialogChooseFile = false

  private rules: any = {
    name: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    category: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    description: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private form: Form = {
    id: 0,
    projectId: 0,
    result: '',
    fileList: [],
    documentIds: [],
  }

  private created() {
    this.form.id = this.task.id
    this.form.projectId = this.selectedProject
  }

  private changeFileList(file, fileList) {
    this.form.fileList = fileList
  }

  private handleExecuteTask() {
    this.executeTask(this.form)
      .then(() => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }

  private deleteDocument(uid) {
    const index = this.form.fileList.findIndex(file => file.uid === uid)
    this.form.fileList.splice(index, 1)
  }

  private handleChooseFile(doc: IDocument) {
    this.form.fileList.push({ name: doc.filename, size: null, raw: null })
    this.form.documentIds.push(doc.id)
  }
}
